import React from "react";
import Layout from "../components/layout";
import '../assets/styles/pages/privacy.scss';
import {SEO} from "../components";
import {Helmet} from "react-helmet";

const Privacy = () => {
    const data = `
        <h1>Privacy Policy</h1>
        <p>MusicRoom LLC operates the <a href="https://www.musicroom.info" target="_blank" rel="noreferrer noopener">https://www.musicroom.info</a> website.</p>
        <p>We collect certain information automatically through cookies and similar tools.</p>
        <p>Cookies are files with small amounts of data which may include a unique identifier. Cookies are sent to your browser from a website and stored on your device.</p>
        <p>We may also collect information that your browser sends whenever you visit our Service or when you access our website by or through a mobile device ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <h2>Statistics</h2>
        <p>The website uses a minimal build of Google Analytics, a service which transmits website traffic data to Google servers in the United States and allows us to notice trends to improve the user experience on our website. This minimal build processes personal data such as: the unique User ID set by Google Analytics, the date and time, the title of the page being viewed, the URL of the page being viewed, the URL of the page that was viewed prior to the current page, the screen resolution, the time in local timezone, the files that were clicked on and downloaded, the links clicked on to an outside domain, the type of device, and the country, region, and city.</p>
        <p>You may opt out of this tracking at any time by activating the “Do Not Track” setting in your browser.</p>
        <h2>Embedded content from other websites</h2>
        <p>Articles on the Website may include embedded content (e.g. videos, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor had visited the other website.</p>
        <p>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracing your interaction with the embedded content if you have an account and are logged in to that website.</p>
        <p>Changes To This Privacy Policy<br> We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <ul>
            <li>By email: <a href="mailto:info@musicroom.info" target="_blank" rel="noreferrer noopener">info@musicroom.info</a></li>
        </ul>
    `
    return (
        <Layout>
            <Helmet title='Privacy Policy'/>

            <div className='privacyContent' dangerouslySetInnerHTML={{__html: data}}/>
        </Layout>
    )
}

export default Privacy;
